import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { flatMap, share, tap } from 'rxjs/operators';

import { IReport } from '../reports-config';
import { ReportsService } from '../reports.service';

@Component({
  selector: 'app-status-tile',
  templateUrl: './status-tile.component.html',
  styleUrls: ['./status-tile.component.scss']
})
export class StatusTileComponent implements OnInit {
  @Input() tileConfig: IReport;

  private readonly FETCH_INTERVAL = 60000;

  status$: Observable<boolean>;
  lastUpdatedTime$: Subject<number> = new Subject();

  constructor(private reportsService: ReportsService) { }

  ngOnInit(): void {
    this.status$ = timer(0, this.FETCH_INTERVAL)
      .pipe(
        flatMap(() => this.reportsService.fetchReportStatus(this.tileConfig.reportUrl)),
        share(),
        tap(() => this.lastUpdatedTime$.next(Date.now()))
      );
  }
}
