import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface ILastRunResult {
  lastRunWasSuccessful: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  fetchReportStatus(url: string): Observable<boolean> {
    return this.http.get<ILastRunResult>(url, { params: { rnd: Date.now().toString() } })
      .pipe(
        map(report => report.lastRunWasSuccessful),
      );
  }
}
