<div class="status-tile">
  <fd-panel area>
    <fd-panel-header [ngClass]="{
        'status-tile--available': status$ | async,
        'status-tile--not-available': (status$ | async) === false }">
      <fd-panel-head>
        <h2 fd-panel-title>{{tileConfig.title}}</h2>
        <fd-panel-description>Last updated time: {{ lastUpdatedTime$ | async | date : 'mediumTime' }}
        </fd-panel-description>
      </fd-panel-head>
    </fd-panel-header>
    <fd-panel-body>
      <div class="status-tile__status">
        <span *ngIf="status$ | async">Status: Available</span>
        <span *ngIf="(status$ | async) === false">Status: NotAvailable</span>
      </div>
      <div class="status-tile__status">
        <span>Regions: {{tileConfig.regions.join(', ')}}</span>
      </div>
    </fd-panel-body>
  </fd-panel>
</div>
