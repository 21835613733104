<fd-shellbar>
  <fd-shellbar-logo>
    <a href="#"
       class="fd-shellbar__logo fd-shellbar__logo--image-replaced"
       aria-label="SAP"></a>
  </fd-shellbar-logo>
  <fd-shellbar-title>
    Customer Data Cloud status page
  </fd-shellbar-title>
</fd-shellbar>
<app-dashboard></app-dashboard>
