import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutGridModule, PanelModule, ShellbarModule } from '@fundamental-ngx/core';

import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StatusTileComponent } from './status-tile/status-tile.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    StatusTileComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ShellbarModule,
    LayoutGridModule,
    PanelModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
