export interface IReport {
  title: string;
  reportUrl: string;
  regions: Array<string>;
}

export const reportsConfig: Array<IReport> = [
  {
    title: 'US1 Console login',
    reportUrl: 'https://console-us1d-status.s3.amazonaws.com/reports/us-login/last-result.json',
    regions: ['US', 'EU', 'AU'],
  },
  {
    title: 'CN1 Console login',
    reportUrl: 'https://console-us1d-status.s3.amazonaws.com/reports/cn-login/last-result.json',
    regions: ['CN'],
  },
];
