import { Component, OnInit } from '@angular/core';

import { reportsConfig } from '../reports-config';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  readonly reportsToShow = reportsConfig;
  ngOnInit(): void { }
}
